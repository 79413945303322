import React from 'react';
import './FreeShippingBanner.css'; // Create this CSS file for styling

const FreeShippingBanner = () => {
  return (
    <div className="banner-container">
      <p className="banner-text">Free Shipping on Orders Over 1000 Rupees</p>
    </div>
  );
};

export default FreeShippingBanner;
